<template>
<div style="width:100%">
  <v-sheet v-if="!registro.gesodt"  :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>INFORMACIÓN ODT</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-form ref="form_cierre">
            <v-row no-gutters>
              <v-col cols="12">
                <v-autocomplete v-model="form_odt.razon_id" :rules="[rules.requerido]" v-on:input="input_razon(form_odt.razon_id)" :color="colores.primario" item-text="descripcion" item-value="id" :items="items.razonllamadas" label="RAZÓN DE LLAMADA T24"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="form_odt.detalle_id" :rules="[rules.requerido]" :color="colores.primario" item-text="descripcion" item-value="id" :items="items_detalles" label="DETALLE LLAMADA T24"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="session.area !== 17">
              <v-col cols="12" class="text-center">
                <v-btn @click="validar" block large :color="colores.black" dark>GUARDAR</v-btn>
              </v-col>
            </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-sheet>
  <br><br>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import recListado from '@/js/rec_listado.js'
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'
// import texto2 from '@/components/widgets/text2Component.vue'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'form_qr_cierreComponent',
  components: {
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  mixins: [recListado, recFunciones, recGestiones],
  props: ['colores', 'items', 'rules', 'gestion_id', 'session', 'form_odt', 'registro'],
  data: () => ({
    loadingDetalle: false,
    items_detalles: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  methods: {
    validar () {
      if (this.$refs.form_cierre.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_cierre_odt()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    input_razon (pRazonId) {
      this.form_odt.detalle_id = 0
      this.rec_listado_razon_llamada(pRazonId)
    }
  }
}
</script>
